import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from, of, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  EmployeeProfileReqModel,
  EmployeeWorkCardHistoryMReqModel,
  HangarMechanicsReqModel,
  TokenReqModel,
  WorkCardFilterCountReqModel,
  WorkCardTimeClockReqModel,
  EmployeeDailyScheduleReqModel,
  EmployeeCurrentAssignmentsReqModel,
  EmpAuthorizations,
  EmployeeAuthorizationsRequestModel,
  PublishAssignmentsReqParams,
  PublishAssignmentsResponse,
  SplittingModelReqParams,
  SplittingModel,
  WorkCardActivityPinReqModel,
  SplitMetadataUpdateReqParams,
  SplitFinishModel,
  WorkStepActionResponse,
  WorkStepActionReqParams,
  ApplyStampReqParams,
  ApplyStampResponse,
  RejectInspectionErrorModel,
  SignatureBlobResponse,
  SignatureBlobRequest,
  CustomerCIRStepReqModel,
  CustomerCIRStepResModel,
  UpdateWorkCardsReqModel,
  LaborClockTrackingResModel,
  LaborClockTrackingReqModel,
  WorkCardPDFAccessReqParams,
  WorkCardPDFAccessResponse,
  GenerateContinuationSheetReqParams,
  GenerateContinuationSheetResponse,
  AuditClosureReviewResModel,
  AuditProcessActivityReqModel,
  AuditProcessActivityResponse,
  PartsListRespModel,
  SaveAnnotationResModel,
  NewPartRequisitionOrderReqModel,
  NewPartRequisitionOrderResModel,
  IssueTagsResModel,
  RoutineStepsGroupActivityReqModal,
  RoutineStepsGroupActivityResModal,
  RoutineWorkCardStepReqModel,
  WorkCardStepResModel,
  WithdrawDetailsReqModal,
  WithdrawDetailsResModal,
  RoutineStepGroupIdListResModel,
  RoutineInspectionFlagResModel,
  AddSceptreFlagReqModel,
  LaborTimeAndCardCountReqModel,
  LaborTimeAndCardCountResModel,
  AuditProcessResubmitActivityReqModel,
  AuditProcessResubmitActivityResponse,
  RoutineCustomerStepsResModel,
  CustomerSignatureBlobRequestModel,
  EmployeeMultiCardAuthCheckRespModel,
  ConfirmAuthRequestModel,
  ConfirmAuthResponseModel,
  UpdatePrimarySkillsResponseModel,
  UpdatePrimarySkillsRequestModel,
  WorkCardSkillsResponseModel,
  StatusReasonListRequestModel,
  ExportProjectRequestModel,
  AddRotableAndRobbedPartsReqModel,
  TechLibraryResModal,
  AddFromSkippedWorkCardsReqModal,
  SaveEmployeeWorkCardNoteRespModel,
  SaveEmployeeWorkCardNoteReqModel,
  CreateTurnOverReportReqModel,
  ExportSingleProjectReqModel,
  UpdateTargetStartDayReqModel,
  UpdateTargetStartDayResModel,
  ConfirmVoidCirReqModel,
  LogPageNumberReqModel,
  WorkCardAssignmentCountResponseModel,
  UserRolesRespModel,
  TokenResModel,
  ActiveInspectionResModel,
  AirworthinessDirectiveReqModel,
  SendToAarPayloadModel,
  userPinReqModel,
  ModifyWorkCardsReqModel,
  TOREmailReqModel,
  TOREmailResponse,
} from '../../widgets/staar/models/request-payload';
import { BoxAccessToken } from '../models/box-access-token';
import { ConcourseUser } from '../models/concourse-user';
import { DomoAccessToken } from '../models/domo-access-token';
import { DomoDashboardEmbedToken } from '../models/domo-dashboard-embed-token';
import { Organization, OrganizationUtil } from '../models/organization';
import { OrganizationClaim } from '../models/organization-claim';
import { PersistedValueEnum } from '../models/persisted-value-enum';
import { UserClaim } from '../models/user-claim';
import { UserOrganization } from '../models/user-organization';
import { Widget } from '../models/widget';
import { HttpService } from './http.service';
import { ApiUtility } from './api-utility';
import { PathConstants } from '../../widgets/staar/constants/mro-path';
import { ProjectDetailsExtModel } from '../../widgets/staar/models/project-details';
import { ProjectFilesModel } from '../../widgets/staar/models/project-files';
import {
  ActiveWorkCardModel,
  EventHistoryResModel,
  KickbackQueueResModel,
  ProjectDetailsWorkCardModel,
  ProjectWorkCardDetailsApiRespModel,
  RecordsProjectDetailsRespModel,
} from '../../widgets/staar/models/projectdetails-workcard';
import { EmployeeDivisionModel } from '../../widgets/staar/models/employee-profile';
import {
  EmployeeWorkCardNoteModel,
  FolderStructureRequestParam,
  FolderStructureResponseParam,
  RequiredPartsExtModel,
} from '../../widgets/staar/models/my-work-card';
import { EmployeeCurrentAssignmentsModel } from '../../widgets/staar/models/employee-current-assignments';
import {
  EmployeeStampsModel,
  TextStampModel,
} from '../../widgets/staar/models/employee-stamps';
import { ModifyWorkcardsModel } from '../../widgets/staar/models/modify-workcards';
import {
  ATACodeResModel,
  ContractGroupResModel,
  CreateDiscrepancyRequestModel,
  CreateDiscrepancyResModel,
  DiscrepancyHeaderResModel,
  DotNumberResModel,
  LocationResModel,
  ReleaseDiscrepancyRequestModel,
  SkillResModel,
  statusReasonResModel,
  EvaluateNRCRequestModel,
  EvaluateNRCResModel,
} from '../../widgets/staar/models/discrepancy-data';
import { ProjectWorkCardExpResponseModel } from '../../widgets/staar/models/export-settings';
import { ManageDiscrepancyResponseModel } from '../../widgets/staar/models/manage-discrepancies';
import {
  AddSceptreFlagResponseModel,
  CardClosureSceptreDetailsResponseModel,
} from '../../widgets/staar/models/sceptre-details';
import { ToolsModel } from '../../widgets/staar/models/tools-history';
import { LeadAssignmentAuthCheckResponseModel } from '../../widgets/staar/models/lead-assignment-auth-check';
import { StatusReasonListResponseModel } from '../../widgets/staar/models/status-reason-list';
import {
  RotableRobbedPartsListDataModel,
  RotableRobbedPartsResponseModel,
} from '../../widgets/staar/models/rotable-robbed-parts';
import { TechData } from '../models/tech-data';
import { LaborDirectFlagResModel } from '../../widgets/staar/models/labor-direct-Flag';
import { PrevWorkCardsAndStndByCardResponseModel } from '../../widgets/staar/models/prev-standby-workcards';
import {
  CreateTurnOverReportResModel,
  TurnOverTypeResModel,
  ShiftCodesResModel,
} from '../../widgets/staar/models/turnover-report';
import { WSModal } from '../../widgets/staar/models/pub-sub';
import { CustomerStampDataRespModel } from '../../widgets/staar/models/customer-stamps';
import { UploadedFilesModel } from '../models/uploadFilesResModel';
import {
  CreateDeleteLogPageNumbersResponseModel,
  GetLogPageNumbersResponseModel,
} from '../../widgets/staar/models/log-page-numbers';
import { ClockedinEmployeesModel } from '../../widgets/staar/models/clockedin-employees';
import { catchError, switchMap } from 'rxjs/operators';
import {
  SupervisorResponseModel,
  SupervisorReview,
} from '../../widgets/staar/models/supervisor-review';
import { CircuitBreakerModel } from '../../widgets/staar/models/circuit-breaker';

@Injectable({
  providedIn: 'root',
})
export class ConcourseApiService {
  private readonly personaManagementPrefix = ApiUtility.personaManagementPrefix;
  private get isLocal(): boolean {
    const isLocal = environment.isLocal;
    if (typeof isLocal === 'string') {
      return (isLocal as string).toLowerCase() === 'true';
    } else if (typeof isLocal === 'boolean') {
      return isLocal === true;
    } else {
      return false;
    }
  }

  public loginUserIcon = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.userInitials) || ''
  );
  public userId = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.userId) || ''
  );
  public userDetails = new BehaviorSubject<ConcourseUser | undefined>(
    undefined
  );
  public organizationId = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.currentOrganizationId) || ''
  );
  public DomoDashboardId = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.currentDomoDashboardId) || ''
  );
  public selectedWidgetName = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.currentWidget) || ''
  );
  public currentOrganization = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.currentOrganizationName) || ''
  );
  public userAzureId = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.userAzureId) || ''
  );
  public userAzureAccessToken = new BehaviorSubject(
    localStorage.getItem(PersistedValueEnum.userAzureAccessToken) || ''
  );

  public static readonly pingUrl = `${ApiUtility.personaManagementPrefix}ping`;

  constructor(private httpService: HttpService, private http: HttpClient) {
    const storedUserDetails = localStorage.getItem(
      PersistedValueEnum.userDetails
    );
    try {
      if (storedUserDetails) {
        this.userDetails.next(JSON.parse(storedUserDetails));
      }
    } catch {
      console.error(`Failed to parse user details - ${storedUserDetails}`);
    }
    // Persist these values so users can refresh the screen
    this.loginUserIcon.subscribe((value) => {
      localStorage.setItem(PersistedValueEnum.userInitials, value);
    });
    this.userId.subscribe((value) => {
      localStorage.setItem(PersistedValueEnum.userId, value);
    });
    this.userDetails.subscribe((value) => {
      localStorage.setItem(
        PersistedValueEnum.userDetails,
        value ? JSON.stringify(value) : ''
      );
    });
    this.organizationId.subscribe((value) => {
      localStorage.setItem(PersistedValueEnum.currentOrganizationId, value);
    });
    this.DomoDashboardId.subscribe((value) => {
      localStorage.setItem(PersistedValueEnum.currentDomoDashboardId, value);
    });
    this.selectedWidgetName.subscribe((value) => {
      localStorage.setItem(PersistedValueEnum.currentWidget, value);
    });
    this.currentOrganization.subscribe((value) => {
      localStorage.setItem(PersistedValueEnum.currentOrganizationName, value);
    });
    this.userAzureId.subscribe((value) => {
      localStorage.setItem(PersistedValueEnum.userAzureId, value);
    });
    this.userAzureAccessToken.subscribe((value) => {
      localStorage.setItem(PersistedValueEnum.userAzureAccessToken, value);
    });
  }

  // Widget API's
  getOrganizationUserWidgetsByOrgIdAndUserId(
    orgId: string | number,
    userId: string | number
  ): Observable<[Widget]> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetOrganizationUserWidgetsByOrgIdAndUserId/${orgId}/${userId}`
    );
  }

  // DOMO API's
  getDomoAccessToken(): Observable<DomoAccessToken> {
    return this.httpService.get(
      `${this.personaManagementPrefix}domo/auth`
    ) as Observable<DomoAccessToken>;
  }

  authorizeDomoDashboard(
    dashboardId: string,
    accessToken: string
  ): Observable<DomoDashboardEmbedToken> {
    const headers = new HttpHeaders({
      accessToken: `${accessToken}`,
    });
    const embedTokenResponse = this.httpService.post(
      `${this.personaManagementPrefix}domo/authorize-dashboard/${dashboardId}`,
      null,
      headers
    );
    return embedTokenResponse as Observable<DomoDashboardEmbedToken>;
  }

  // Box API's
  getBoxAccessToken(folderId: string): Observable<BoxAccessToken> {
    return this.httpService.get(
      `${this.personaManagementPrefix}box/token/${folderId}`
    ) as Observable<BoxAccessToken>;
  }

  // GraphAPI API's
  getCustomClaims(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.userAzureAccessToken.value}`,
    });
    return this.httpService.get(
      `${this.personaManagementPrefix}custom-attributes?userId=${this.userAzureId.value}`,
      headers
    );
  }

  /// User API's
  getUserByEmail(email: string): Observable<ConcourseUser> {
    // Concourse Persona Management API Prefix - Registration/
    const userByEmail = this.httpService.get(
      `${this.personaManagementPrefix}GetUserByEmail/${email}`,
      null,
      null,
      true,
      this.handleUserByEmailError
    );
    return userByEmail as Observable<ConcourseUser>;
  }

  getUserClaimsByUserId(id: string | number): Observable<UserClaim[]> {
    // Concourse Persona Management API Prefix - User/
    return this.httpService.get(
      `${this.personaManagementPrefix}GetUserClaimsByUserId/${id}`
    ) as Observable<UserClaim[]>;
  }

  insertUserClaim(claim: UserClaim): Observable<void> {
    return this.httpService.post(
      `${this.personaManagementPrefix}InsertUserClaim`,
      JSON.stringify(claim)
    );
  }

  updateUserClaim(claim: UserClaim): Observable<void> {
    return this.httpService.post(
      `${this.personaManagementPrefix}UpdateUserClaim`,
      JSON.stringify(claim)
    );
  }

  getUserOrganizationsByOrganizationId(
    organizationId: string | number
  ): Observable<UserOrganization[]> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetUserOrganizationsByOrganizationId/${organizationId}`
    ) as Observable<UserOrganization[]>;
  }

  /// Organization API's
  getOrganizationsByUserId(id: string | number): Observable<Organization[]> {
    // Concourse Persona Management API Prefix - User/
    const organizations: Observable<Organization[]> = this.httpService.get(
      `${this.personaManagementPrefix}GetOrganizationsByUserId/${id}`,
      null,
      null,
      true,
      this.handleGetOrganizationsByUserIdError
    );
    return organizations;
  }

  getOrganizationClaimsByOrganizationId(
    organizationId: string | number
  ): Observable<OrganizationClaim[]> {
    // Concourse Persona Management API Prefix - Organization/
    return this.httpService.get(
      `${this.personaManagementPrefix}GetOrganizationClaimsByOrganizationId/${organizationId}`
    ) as Observable<OrganizationClaim[]>;
  }

  // StAAR API's
  getEmployeeAPI(subUrl: string, data: any) {
    return this.httpService.get(
      `${subUrl}?employeeNumber=${data.employeeNumber}&divNo=${data.divNo}&token=${data.token}`
    );
  }

  getToken(subUrl: string, data: TokenReqModel): Observable<TokenResModel> {
    return this.httpService.get(
      `${subUrl}?employeeId=${data.employeeId}&application=${data.application}&channel=${data.channel}`
    );
  }

  getProjectList(
    subUrl: string,
    queryString: string
  ): Observable<ProjectDetailsExtModel> {
    return this.callGetApi(subUrl, queryString);
  }

  getProjectWorkCardList(
    subUrl: string,
    queryString: string
  ): Observable<ProjectDetailsWorkCardModel> {
    return this.callGetApi(subUrl, queryString);
  }

  getTimeClockInfo(subUrl: string, data: WorkCardTimeClockReqModel) {
    return this.httpService.get(
      `${subUrl}?divNo=${data.divNo}&itemNumber=${data.itemNumber}&zoneNumber=${data.zoneNumber}&workOrderNumber=${data.workOrderNumber}&token=${data.token}`
    );
  }

  getHangarMechanics(subUrl: string, data: HangarMechanicsReqModel) {
    return this.getEmployeeAPI(subUrl, data);
  }

  getEmployeeProfile(subUrl: string, data: EmployeeProfileReqModel) {
    return this.getEmployeeAPI(subUrl, data);
  }

  getCustomerProfile(subUrl: string, data: EmployeeProfileReqModel) {
    return this.httpService.get(
      `${subUrl}?customerRepNumber=${data.customerRepNumber}&divNo=${data.divNo}&token=${data.token}`
    );
  }

  getEmployeeWorkCardHistory(
    subUrl: string,
    data: EmployeeWorkCardHistoryMReqModel
  ) {
    return this.getEmployeeAPI(subUrl, data);
  }

  getWorkCardFilterCounts(subUrl: string, data: WorkCardFilterCountReqModel) {
    return this.httpService.get(
      `${subUrl}?divNo=${data.divNo}&projectNo=${data.projectNo}&token=${data.token}`
    );
  }

  postWorkCardActivityPin(
    subUrl: string,
    pinnedModel
  ): Observable<WorkCardActivityPinReqModel> {
    const headers = new HttpHeaders({
      pinnedModel: `${pinnedModel}`,
    });

    const pinnedModelResponse = this.httpService.post(
      subUrl,
      pinnedModel,
      headers
    );
    return pinnedModelResponse;
  }

  getEmployeeDailySchedule(
    subUrl: string,
    data: EmployeeDailyScheduleReqModel
  ): Observable<any> {
    return this.httpService.get(
      `${subUrl}?employeeNumber=${data.employeeNumber}&divNo=${data.divNo}&fromDate=${data.fromDate}&toDate=${data.toDate}&token=${data.token}`
    );
  }

  getProjectFilesList(
    subUrl: string,
    queryString: string
  ): Observable<ProjectFilesModel> {
    return this.callGetApi(subUrl, queryString);
  }

  getEmployeeCurrentAssignments(
    subUrl: string,
    data: EmployeeCurrentAssignmentsReqModel
  ): Observable<EmployeeCurrentAssignmentsModel[]> {
    return this.getEmployeeAPI(subUrl, data);
  }

  uploadFiles(
    subUrl: string,
    fromData: FormData
  ): Observable<UploadedFilesModel[]> {
    return this.httpService.postFormData(subUrl, fromData);
  }

  publishAssignments(
    reqParams: PublishAssignmentsReqParams[]
  ): Observable<PublishAssignmentsResponse[]> {
    return this.httpService.post(
      PathConstants.publishAssignments,
      reqParams,
      new HttpHeaders({})
    );
  }
  


  workStepAction(
    reqParams: WorkStepActionReqParams
  ): Observable<WorkStepActionResponse> {
    return this.httpService.post(
      PathConstants.workStepAction,
      reqParams,
      new HttpHeaders({})
    );
  }

  applyStamp(reqParams: ApplyStampReqParams): Observable<ApplyStampResponse> {
    return this.httpService.post(
      PathConstants.applyStamp,
      reqParams,
      new HttpHeaders({})
    );
  }

  getWorkCardSteps(subUrl: string, queryStringParam: string): Observable<any> {
    return this.callGetApi(subUrl, queryStringParam);
  }

  getWorkCardFilesList(subUrl: string, queryString: string): Observable<any> {
    return this.callGetApi(subUrl, queryString);
  }

  getEmployeeDivisionList(
    subUrl: string,
    queryString: string
  ): Observable<EmployeeDivisionModel[]> {
    return this.callGetApi(subUrl, queryString);
  }

  getRequiredPartsList(
    subUrl: string,
    queryString: string
  ): Observable<RequiredPartsExtModel> {
    return this.callGetApi(subUrl, queryString);
  }

  getEmployeePinValidation(
    subUrl: string,
    queryString: string
  ): Observable<any> {
    return this.callGetApi(subUrl, queryString);
  }

  getValidatingaborClock(subUrl: string, queryString: string): Observable<any> {
    return this.callGetApi(subUrl, queryString);
  }

  getEmployeeStampsList(
    subUrl: string,
    queryStringEmployeeStamp: string
  ): Observable<EmployeeStampsModel[]> {
    return this.httpService.get(`${subUrl}?${queryStringEmployeeStamp}`);
  }

  getProjectWorkCardExpDetails(
    subUrl: string,
    queryStringProjectWorkCardExpDetail: string
  ): Observable<ProjectWorkCardExpResponseModel[]> {
    return this.httpService.get(
      `${subUrl}?${queryStringProjectWorkCardExpDetail}`
    );
  }
  // Private Methods
  private handleUserByEmailError(
    error: any
  ): Observable<any> | Observable<never> {
    return this.isLocal
      ? of(ConcourseUser.localDevelopmentUser)
      : throwError(error);
  }

  private handleGetOrganizationsByUserIdError(
    error: any
  ): Observable<Organization[]> | Observable<never> {
    return this.isLocal
      ? of(OrganizationUtil.localDevelopmentOrganizations)
      : throwError(error);
  }

  private constructQueryString(params: {}) {
    const stringifiedParams = {};
    Object.entries(params).forEach(([key, val]) => {
      stringifiedParams[key] = String(val);
    });
    return new URLSearchParams(stringifiedParams);
  }

  getWorkCardSplitModel(
    splitModelReqParams: SplittingModelReqParams,
    splitType?: string
  ): Observable<SplittingModel> {
    return this.httpService.post(
      `${PathConstants.buildSplittingModel}?splitType=${splitType}`,
      splitModelReqParams,
      new HttpHeaders({})
    );
  }

  validateMetaDataAndUpdate(
    splitMetadataUpdateReqParams: SplitMetadataUpdateReqParams
  ): Observable<SplittingModel> {
    return this.httpService.post(
      PathConstants.validateMetaDataAndUpdate,
      splitMetadataUpdateReqParams,
      new HttpHeaders({})
    );
  }

  createTaskCards(
    splitMetadataUpdateReqParams: SplitMetadataUpdateReqParams
  ): Observable<SplitFinishModel> {
    return this.httpService.post(
      PathConstants.createTaskCards,
      splitMetadataUpdateReqParams,
      new HttpHeaders({})
    );
  }

  postEmployeeAuthorizations(
    subUrl: string,
    employeeAuthModel: EmployeeAuthorizationsRequestModel
  ): Observable<EmpAuthorizations[]> {
    const headers = new HttpHeaders({});

    const employeeAuthModelResponse = this.httpService.post(
      subUrl,
      employeeAuthModel,
      headers
    );
    return employeeAuthModelResponse;
  }

  getTools(subUrl: string, queryString: string): Observable<ToolsModel[]> {
    return this.callGetApi(subUrl, queryString);
  }

  /** get user signature stamp */
  getSignatureBlobs(
    requestObject: SignatureBlobRequest
  ): Observable<SignatureBlobResponse[]> {
    let queryParams: HttpParams = new HttpParams({
      fromObject: { ...requestObject },
    });
    return this.httpService.get(
      `${PathConstants.getEmployeeStamps}`,
      null,
      queryParams
    );
  }

  getAnnotationDataService(
    subUrl: string,
    queryParam: string
  ): Observable<Blob> {
    return this.httpService.getBlob(`${subUrl}?${queryParam}`);
  }

  uploadAnnotationDataService(
    subUrl: string,
    data: FormData
  ): Observable<SaveAnnotationResModel> {
    return this.httpService.postFormData(`${subUrl}`, data);
  }

  uploadAnnotationXmlService(
    queryString: string,
    xml: string | Blob
  ): Observable<SaveAnnotationResModel> {
    const url = `${PathConstants.uploadAnnotationPath}?${queryString}`;
    const headers = new HttpHeaders({ 'Content-Type': 'application/xml' });

    const normalizeXml = (xml) =>
      typeof xml === 'string' ? of(xml) : from(xml.text());

    return normalizeXml(xml).pipe(
      switchMap((xmlString) => this.httpService.post(url, xmlString, headers))
    );
  }

  getRejectInspectionOptions(
    subUrl: string,
    queryParamString: string
  ): Observable<RejectInspectionErrorModel[]> {
    return this.httpService.get(`${subUrl}?${queryParamString}`);
  }

  /** Task-20211 */
  addCustomerCIRStep(
    customerCIRStepReqParams: CustomerCIRStepReqModel
  ): Observable<CustomerCIRStepResModel> {
    return this.httpService.post(
      PathConstants.customerStepsAction,
      customerCIRStepReqParams,
      new HttpHeaders({})
    );
  }

  setSupervisorReviewAudit(
    setSupervisorReviewPayload: SupervisorReview
  ): Observable<SupervisorResponseModel> {
    return this.httpService.post(
      PathConstants.supervisorReviewAudit,
      setSupervisorReviewPayload,
      new HttpHeaders({})
    );
  }

  updateWorkCards(
    updateWorkCardsReqParams: UpdateWorkCardsReqModel[]
  ): Observable<ModifyWorkcardsModel[]> {
    return this.httpService.post(
      PathConstants.modifyWorkCards,
      updateWorkCardsReqParams,
      new HttpHeaders({})
    );
  }

  modifyWorkCards(
    modifyWorkCardsReqParams: ModifyWorkCardsReqModel[]
  ): Observable<ModifyWorkcardsModel[]> {
    return this.httpService.post(
      PathConstants.modifyWorkCards,
      modifyWorkCardsReqParams,
      new HttpHeaders({})
    );
  }

  updateAirworthinessFlag(
    payload: AirworthinessDirectiveReqModel[]
  ): Observable<ModifyWorkcardsModel[]> {
    return this.httpService.post(
      PathConstants.modifyWorkCards,
      payload,
      new HttpHeaders({})
    );
  }

  customerSendWorkCard(payload: SendToAarPayloadModel): Observable<any> {
    return this.httpService.post(
      PathConstants.customerSendWorkCard,
      payload,
      new HttpHeaders({})
    );
  }

  startStopLaborClockService(
    clockRequestParam: LaborClockTrackingReqModel[],
    programId: string
  ): Observable<LaborClockTrackingResModel[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('programId', programId);
    params = params.set('terminalId', localStorage.getItem('IP'));
    return this.httpService.post(
      PathConstants.laborClockTracking,
      clockRequestParam,
      new HttpHeaders({}),
      params
    );
  }

  workCardPDFAccess(
    pdfAccessReqParams: WorkCardPDFAccessReqParams
  ): Observable<WorkCardPDFAccessResponse> {
    return this.httpService.post(
      PathConstants.workCardPDFAccess,
      pdfAccessReqParams,
      new HttpHeaders({})
    );
  }

  getAuditClosureReview(
    subUrl: string,
    queryParams: string
  ): Observable<AuditClosureReviewResModel[]> {
    return this.httpService.get(`${subUrl}?${queryParams}`);
  }

  getCircuitBreakers(
    subUrl: string,
    queryParams: string
  ): Observable<CircuitBreakerModel[]> {
    return this.httpService.get(`${subUrl}?${queryParams}`);
  }

  auditProcessActivity(
    auditProcessActivityReqModel: AuditProcessActivityReqModel
  ): Observable<AuditProcessActivityResponse> {
    return this.httpService
      .post(
        PathConstants.auditProcessActivity,
        auditProcessActivityReqModel,
        new HttpHeaders({})
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(null);
        })
      );
  }

  submitClosureReview(
    auditProcessResubmitActivityReqModel: AuditProcessResubmitActivityReqModel
  ): Observable<AuditProcessActivityResponse> {
    return this.httpService.post(
      PathConstants.submitClosureReview,
      auditProcessResubmitActivityReqModel,
      new HttpHeaders({})
    );
  }

  auditProcessResubmitActivity(
    auditProcessResubmitActivityReqModel: AuditProcessResubmitActivityReqModel
  ): Observable<AuditProcessResubmitActivityResponse> {
    return this.httpService.post(
      PathConstants.auditProcessResubmitActivity,
      auditProcessResubmitActivityReqModel,
      new HttpHeaders({})
    );
  }

  getKickbackCardQueueList(
    queryString: string
  ): Observable<KickbackQueueResModel> {
    return this.httpService.get(
      `${PathConstants.projectWorkCardList}?${queryString}`
    );
  }

  /** get user cir designator Stamp */
  getCirDesignatorStamp(requestObject): Observable<string> {
    let queryParams: HttpParams = new HttpParams({
      fromObject: { ...requestObject },
    });
    return this.httpService.postCir(
      `${PathConstants.getCirDesignatorStamp}`,
      'text',
      queryParams
    );
  }

  //Generate continuation sheet api configuration.
  generateContinuationSheet(
    generateContinuationSheetReqParams: GenerateContinuationSheetReqParams
  ): Observable<GenerateContinuationSheetResponse> {
    return this.httpService.post(
      PathConstants.generateContinuationSheet,
      generateContinuationSheetReqParams,
      new HttpHeaders({})
    );
  }

  getLocationList(
    subUrl: string,
    locationQueryString: string
  ): Observable<LocationResModel[]> {
    return this.httpService.get(`${subUrl}?${locationQueryString}`);
  }

  getAtaCodeList(
    subUrl: string,
    ataQueryString: string
  ): Observable<ATACodeResModel[]> {
    return this.httpService.get(`${subUrl}?${ataQueryString}`);
  }

  getSkillsList(
    subUrl: string,
    skillsQueryString: string
  ): Observable<SkillResModel[]> {
    return this.httpService.get(`${subUrl}?${skillsQueryString}`);
  }

  getDiscrepancyStatusReasonList(
    subUrl: string,
    statusReasonQueryString: string
  ): Observable<statusReasonResModel[]> {
    return this.httpService.get(`${subUrl}?${statusReasonQueryString}`);
  }

  getDiscrepancyContractGroupList(
    subUrl: string,
    contractGroupQueryString: string
  ): Observable<ContractGroupResModel[]> {
    return this.httpService.get(`${subUrl}?${contractGroupQueryString}`);
  }

  getDiscrepancyDotNumber(
    subUrl: string,
    dotNumberQueryString: string
  ): Observable<DotNumberResModel> {
    return this.httpService.get(`${subUrl}?${dotNumberQueryString}`);
  }

  getDiscrepancyHeadersData(
    subUrl: string,
    discrepancyQueryString: string
  ): Observable<DiscrepancyHeaderResModel> {
    return this.httpService.get(`${subUrl}?${discrepancyQueryString}`);
  }

  createDiscrepancy(
    createDiscrepancyReqParams: CreateDiscrepancyRequestModel
  ): Observable<CreateDiscrepancyResModel> {
    return this.httpService.post(
      PathConstants.createDiscrepancy,
      createDiscrepancyReqParams,
      new HttpHeaders({})
    );
  }

  getProjectDetailsList(
    subUrl: string,
    queryString: string
  ): Observable<RecordsProjectDetailsRespModel> {
    return this.callGetApi(subUrl, queryString);
  }

  getPartListService(
    subUrl: string,
    queryString: string
  ): Observable<PartsListRespModel> {
    return this.callGetApi(subUrl, queryString);
  }

  updateDiscrepancy(
    createDiscrepancyReqParams: CreateDiscrepancyRequestModel
  ): Observable<CreateDiscrepancyResModel> {
    return this.httpService.post(
      PathConstants.updateDiscrepancy,
      createDiscrepancyReqParams,
      new HttpHeaders({})
    );
  }

  releaseDiscrepancy(
    createDiscrepancyReqParams: ReleaseDiscrepancyRequestModel
  ): Observable<CreateDiscrepancyResModel> {
    return this.httpService.post(
      PathConstants.releaseDiscrepancy,
      createDiscrepancyReqParams,
      new HttpHeaders({})
    );
  }

  getEventHistoryList(
    subUrl: string,
    eventQueryString: string
  ): Observable<EventHistoryResModel[]> {
    return this.httpService.get(`${subUrl}?${eventQueryString}`);
  }

  getManageDiscrepancyList(
    subUrl: string,
    queryString: string
  ): Observable<ManageDiscrepancyResponseModel[]> {
    return this.callGetApi(subUrl, queryString);
  }

  getCardClosureSceptreDetails(
    subUrl: string,
    cardClosureSceptreDetailsQueryString: string
  ): Observable<CardClosureSceptreDetailsResponseModel> {
    return this.httpService.get(
      `${subUrl}?${cardClosureSceptreDetailsQueryString}`
    );
  }

  addSceptreData(
    addSceptreFlagReqModel: AddSceptreFlagReqModel
  ): Observable<AddSceptreFlagResponseModel> {
    return this.httpService.post(
      PathConstants.addSceptreData,
      addSceptreFlagReqModel,
      new HttpHeaders({})
    );
  }
  /** get user signature stamp */
  getPartFolderStructure(
    requestObject: FolderStructureRequestParam
  ): Observable<FolderStructureResponseParam[]> {
    let queryParams: HttpParams = new HttpParams({
      fromObject: { ...requestObject },
    });
    return this.httpService.get(
      `${PathConstants.partFolderStructure}`,
      null,
      queryParams
    );
  }

  newPartRequisitionOrderService(
    params: NewPartRequisitionOrderReqModel[]
  ): Observable<NewPartRequisitionOrderResModel[]> {
    return this.httpService.post(PathConstants.newPartRequisitionOrder, params);
  }

  generatePartsIssuedtags(
    queryParams: string
  ): Observable<IssueTagsResModel[]> {
    return this.httpService.get(
      `${PathConstants.generatePartsIssuedtags}?${queryParams}`
    );
  }

  routineStepsGroupActivityService(
    reqParams: RoutineStepsGroupActivityReqModal
  ): Observable<RoutineStepsGroupActivityResModal> {
    return this.httpService.post(
      PathConstants.routineStepsGroupActivity,
      reqParams,
      new HttpHeaders({})
    );
  }

  addUpdateDeleteRoutineWorkCardSteps(
    wcStepReqParams: RoutineWorkCardStepReqModel
  ): Observable<WorkCardStepResModel> {
    return this.httpService.post(
      PathConstants.routineWorkCardStepsAction,
      wcStepReqParams,
      new HttpHeaders({})
    );
  }

  withdrawnCardDetails(
    reqModel: WithdrawDetailsReqModal
  ): Observable<WithdrawDetailsResModal> {
    return this.httpService.post(
      PathConstants.withdrawnCardDetails,
      reqModel,
      new HttpHeaders({})
    );
  }

  withdrawCard(
    reqModel: WithdrawDetailsReqModal
  ): Observable<AuditProcessActivityResponse> {
    return this.httpService.post(
      PathConstants.modifyCardStatus,
      reqModel,
      new HttpHeaders({})
    );
  }
  getRoutineInspectionFlag(
    subUrl: string,
    queryString: string
  ): Observable<RoutineInspectionFlagResModel[]> {
    return this.callGetApi(subUrl, queryString);
  }

  getRoutineStampDetails(
    subUrl: string,
    queryString: string
  ): Observable<RoutineStepGroupIdListResModel[]> {
    return this.callGetApi(subUrl, queryString);
  }

  getLaborTimeAndCardCountService(
    params: LaborTimeAndCardCountReqModel
  ): Observable<LaborTimeAndCardCountResModel> {
    return this.httpService.post(
      PathConstants.laborTimeAndCardCount,
      params,
      new HttpHeaders({})
    );
  }

  saveEvaluteNRCData(
    evaluateNRCReqParams: EvaluateNRCRequestModel
  ): Observable<EvaluateNRCResModel> {
    return this.httpService.post(
      PathConstants.evaluateNRC,
      evaluateNRCReqParams,
      new HttpHeaders({})
    );
  }

  getRoutineCustomerDesignatorSteps(
    subUrl: string,
    queryStringParam: string
  ): Observable<RoutineCustomerStepsResModel[]> {
    return this.callGetApi(subUrl, queryStringParam);
  }

  getActiveWorkCardList(
    subUrl: string,
    queryString: string
  ): Observable<ActiveWorkCardModel[]> {
    return this.callGetApi(subUrl, queryString);
  }

  getEmployeeMultiCardAuthStatus(
    subUrl: string,
    employeeMultiCardAuthCheckQueryString: string
  ): Observable<EmployeeMultiCardAuthCheckRespModel> {
    return this.httpService.get(
      `${subUrl}?${employeeMultiCardAuthCheckQueryString}`
    );
  }

  createCustomerStamp(
    createCustomerStampReqParams: CustomerSignatureBlobRequestModel
  ): Observable<string> {
    let queryParams: HttpParams = new HttpParams({
      fromObject: { ...createCustomerStampReqParams },
    });
    return this.httpService.postCir(
      `${PathConstants.createCustomerStamp}`,
      'text',
      queryParams
    );
  }

  leadAssignmentAuthCheck(
    subUrl: string,
    leadAssignmentAuthCheckQueryString: string
  ): Observable<LeadAssignmentAuthCheckResponseModel[]> {
    return this.httpService.get(
      `${subUrl}?${leadAssignmentAuthCheckQueryString}`
    );
  }

  confirmAuth(
    reqParams: ConfirmAuthRequestModel[]
  ): Observable<ConfirmAuthResponseModel> {
    return this.httpService.post(
      PathConstants.confirmAuth,
      reqParams,
      new HttpHeaders({})
    );
  }

  exportProject(
    exportProjectReqParams: ExportProjectRequestModel
  ): Observable<any> {
    return this.httpService.post(
      PathConstants.exportProject,
      exportProjectReqParams,
      new HttpHeaders({})
    );
  }

  updatePrimarySkills(
    params: UpdatePrimarySkillsRequestModel
  ): Observable<UpdatePrimarySkillsResponseModel> {
    return this.httpService.post(PathConstants.updatePrimarySkills, params);
  }

  getWorkCardSkills(
    subUrl: string,
    queryStringWorkCardSkillsParam: string
  ): Observable<WorkCardSkillsResponseModel[]> {
    return this.callGetApi(subUrl, queryStringWorkCardSkillsParam);
  }

  statusReasonList(
    params: StatusReasonListRequestModel
  ): Observable<StatusReasonListResponseModel> {
    return this.httpService.post(PathConstants.statusReasonList, params);
  }

  getRotableAndRobbedPartsList(
    subUrl: string,
    queryString: string
  ): Observable<RotableRobbedPartsListDataModel[]> {
    return this.callGetApi(subUrl, queryString);
  }

  createRotableAndRobbedPartsData(
    params: AddRotableAndRobbedPartsReqModel[]
  ): Observable<RotableRobbedPartsResponseModel[]> {
    return this.httpService.post(PathConstants.createRotableRobbedStep, params);
  }

  employeesAssignedOnWorkCard(
    subUrl: string,
    queryStringWorkCardSkillsParam: string
  ): Observable<any[]> {
    return this.callGetApi(subUrl, queryStringWorkCardSkillsParam);
  }

  assignUnassignEmployee(params) {
    return this.httpService.post(PathConstants.createWorkCardActivity, params);
  }

  uploadTechLibrary(techData: TechData[]): Observable<TechLibraryResModal[]> {
    return this.httpService.post(
      PathConstants.addFromTechLibrary,
      techData,
      new HttpHeaders({})
    );
  }

  getProjectWorkCardDetailsService(
    subUrl: string,
    queryParam: string
  ): Observable<ProjectWorkCardDetailsApiRespModel> {
    return this.httpService.get(`${subUrl}?${queryParam}`);
  }

  addFileToWorkcardServices(
    payloadData: AddFromSkippedWorkCardsReqModal[]
  ): Observable<AddFromSkippedWorkCardsReqModal[]> {
    return this.httpService.post(
      PathConstants.addFromSkippedWorkCards,
      payloadData
    );
  }

  laborDirectFlag(params): Observable<LaborDirectFlagResModel> {
    return this.httpService.post(PathConstants.laborDirectFlag, params);
  }

  getPrevWorkCardsAndStndByCard(
    subUrl: string,
    queryStringLTT: string
  ): Observable<PrevWorkCardsAndStndByCardResponseModel> {
    return this.httpService.get(`${subUrl}?${queryStringLTT}`);
  }

  getEmployeeWorkCardNoteService(
    subUrl: string,
    queryStringNote: string
  ): Observable<EmployeeWorkCardNoteModel> {
    return this.httpService.get(`${subUrl}?${queryStringNote}`);
  }

  saveEmployeeWorkCardNoteService(
    params: SaveEmployeeWorkCardNoteReqModel
  ): Observable<SaveEmployeeWorkCardNoteRespModel> {
    return this.httpService.post(PathConstants.employeeWorkCardNote, params);
  }

  getShiftCodesList(
    subUrl: string,
    shiftCodesQueryString: string
  ): Observable<ShiftCodesResModel[]> {
    return this.httpService.get(`${subUrl}?${shiftCodesQueryString}`);
  }

  createTurnOverReport(
    createTurnOverReportReqParams: CreateTurnOverReportReqModel
  ): Observable<CreateTurnOverReportResModel> {
    return this.httpService.post(
      PathConstants.createTurnOverReport,
      createTurnOverReportReqParams,
      new HttpHeaders({})
    );
  }

  getTurnoverReportDataService(
    subUrl: string,
    queryStringTorp: string
  ): Observable<any> {
    return this.httpService.get(`${subUrl}?${queryStringTorp}`);
  }

  getTurnOverTypeList(
    subUrl: string,
    TurnOverTypeQueryString: string
  ): Observable<TurnOverTypeResModel[]> {
    return this.httpService.get(`${subUrl}?${TurnOverTypeQueryString}`);
  }

  exportItem(
    exportSingleProjReqModel: ExportSingleProjectReqModel,
    workCardId: number
  ): Observable<any> {
    return this.httpService.postBlob(
      `${PathConstants.exportItem}/${workCardId}`,
      exportSingleProjReqModel,
      new HttpHeaders()
    );
  }

  getWssUrl(subUrl: string, wssQueryString: string): Observable<WSModal> {
    return this.httpService.get(`${subUrl}?${wssQueryString}`);
  }

  addRemoveUserToGroup(subUrl: string, queryString: string): Observable<void> {
    return this.httpService.post(
      `${subUrl}?${queryString}`,
      null,
      new HttpHeaders({})
    );
  }

  getCustomerStampDataByUserId(
    subUrl: string,
    custStampQuery: string
  ): Observable<CustomerStampDataRespModel> {
    return this.httpService.get(`${subUrl}/${custStampQuery}`);
  }

  getCustomerStamps(subUrl: string, custStampQuery: object): Observable<any> {
    return this.httpService.get(
      `${subUrl}/${custStampQuery['divNo']}/${custStampQuery['employeeNo']}/${custStampQuery['token']}/${custStampQuery['scale']}/${custStampQuery['isImage']}/${custStampQuery['includeWithdrawn']}/${custStampQuery['customerOrgId']}`
    );
  }

  getVisualSignatureImage(
    subUrl: string,
    custStampQuery: object
  ): Observable<any> {
    return this.httpService.get(
      `${subUrl}/${custStampQuery['signatureText']}/${custStampQuery['divNo']}/${custStampQuery['employeeNo']}/${custStampQuery['token']}/${custStampQuery['scale']}/${custStampQuery['isImage']}/${custStampQuery['includeWithdrawn']}/${custStampQuery['customerOrgId']}`
    );
  }

  getNAStampService(
    subUrl: string,
    textStampQuery: string
  ): Observable<TextStampModel[]> {
    return this.httpService.post(`${subUrl}?${textStampQuery}`, null);
  }

  getVisualSignatureStamp(params: {
    signatureText: string;
    scale: number;
    token: string;
  }): Observable<string> {
    const queryString = this.constructQueryString(params);
    const requestUrl = `/concourse/image/createVisualSignatureImage?${queryString}`;
    return this.httpService.get(requestUrl).pipe(
      catchError((err) => {
        // This is a hack to get around a mismatch
        // between content-type header (application/json)
        // and the response body (raw base64-encoded string)
        const errorText = err?.error?.toString();
        const responseText = err?.text;
        if (errorText.match(/SyntaxError/i)) {
          return of(responseText);
        }
        throw err?.error || err;
      })
    );
  }

  callGetApi(subUrl: string, queryString: string) {
    return this.httpService.get(`${subUrl}?${queryString}`);
  }

  updateTargetStartDay(
    updateTargetStartDayReqParams: UpdateTargetStartDayReqModel
  ): Observable<UpdateTargetStartDayResModel> {
    return this.httpService.post(
      PathConstants.updateTargetStartDay,
      updateTargetStartDayReqParams,
      new HttpHeaders({})
    );
  }

  confirmVoidCIRData(
    confirmVoidCirReqModel: ConfirmVoidCirReqModel
  ): Observable<CustomerCIRStepResModel> {
    return this.httpService.post(
      PathConstants.customerStepsAction,
      confirmVoidCirReqModel,
      new HttpHeaders({})
    );
  }

  getLogPageNumberService(
    subUrl: string,
    queryStringLogPageNumber: string
  ): Observable<GetLogPageNumbersResponseModel[]> {
    return this.httpService.get(`${subUrl}?${queryStringLogPageNumber}`);
  }

  createUpdateLogPageNumberService(
    createUpdateLogPageNumberReqParams: LogPageNumberReqModel
  ): Observable<CreateDeleteLogPageNumbersResponseModel> {
    return this.httpService.post(
      PathConstants.createLogPageNumber,
      createUpdateLogPageNumberReqParams,
      new HttpHeaders({})
    );
  }

  clockedInEmployeeListService(
    subUrl: string,
    clockedInEmployee: string
  ): Observable<ClockedinEmployeesModel[]> {
    return this.httpService.get(`${subUrl}?${clockedInEmployee}`);
  }

  getWorkCardAssignmentCount(
    subUrl: string,
    queryStringPublishCount: string
  ): Observable<WorkCardAssignmentCountResponseModel> {
    return this.httpService.get(`${subUrl}?${queryStringPublishCount}`);
  }

  downloadUrl(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: 'blob',
    });
  }

  updateUserPIN(
    userPinUpdateReqParams: userPinReqModel
  ): Observable<userPinReqModel> {
    return this.httpService.post(
      PathConstants.updatePIN,
      userPinUpdateReqParams,
      new HttpHeaders({})
    );
  }

  sendTurnOverReportEmail(
    sendTOREmailReqParams: TOREmailReqModel
  ): Observable<TOREmailResponse> {
    return this.httpService.post(
      PathConstants.sendTOREmail,
      sendTOREmailReqParams,
      new HttpHeaders({})
    );
  }

  getUserPINDetails(queryParams: string): Observable<any> {
    return this.httpService.get(`${PathConstants.auditPIN}?${queryParams}`);
  }

  checkUserPINAvailability(queryParams: string): Observable<any> {
    return this.httpService.get(
      `${PathConstants.checkPINAvailability}?${queryParams}`
    );
  }

  getUserRoleByUserId(
    userId: string | number
  ): Observable<UserRolesRespModel[]> {
    return this.httpService.get(
      `${this.personaManagementPrefix}GetUserRolesByUserId/${userId}`
    );
  }

  getActiveInspection(
    subUrl: string,
    queryString: string
  ): Observable<ActiveInspectionResModel[]> {
    return this.callGetApi(subUrl, queryString);
  }

  public getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  getCardClosureCheckList(queryParams: string): Observable<any> {
    return this.httpService.get(
      `${PathConstants.cardClosureCheckList}?${queryParams}`
    );
  }

  getRecordQueueCheckList(queryParams: string): Observable<any> {
    return this.httpService.get(
      `${PathConstants.recordQueueCheckList}?${queryParams}`
    );
  }

  workStepFinalAction(
    workStepFinalActionReqModel: any,
    workOrderType: any
  ): Observable<any> {
    let url = `${PathConstants.workStepFinalAction}?workOrderType=${workOrderType}`;
    return this.httpService.post(
      url,
      workStepFinalActionReqModel,
      new HttpHeaders({})
    );
  }

  callSupervisorSendCardToLead(reqParams: any): Observable<any> {
    return this.httpService.post(
      PathConstants.supervisorSendCardToLead,
      reqParams,
      new HttpHeaders({})
    );
  }
}
