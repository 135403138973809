// Request model for token generation api call
export class TokenReqModel {
  public employeeId: string;
  public application: string = "CONCOURSE";
  public channel: string = "Browser";
}

export class TokenResModel {
  token: string
  employeeNumber: string
  divNo: number
  divName: string
  divCount: number
}

// Request model for projectList api call
export class ProjectListReqModel {
  public employeeNumber: string;
  public divNo: string | number;
  public persona: string = 'Lead';
  public token: string;
  public pageNo: number;
  public pageSize: number;
  public sortingField: string;
  public sortingOrder: string;
  public projectNo: string;
  public customerName: string;
  public status: string;
  public headers: boolean;
}

// Request model for workcardlist api call
export class ProjectWorkCardListReqModel {
  public employeeNumber: string | number;
  public divNo: string | number;
  public projectNo: string;
  public token: string
  public pageNo: number;
  public pageSize: number;
  public headers: boolean;
  public countFilterField: string;
  public sortingField: string;
  public sortingOrder: string;
  public status: string;
  public workOrderType: string;
  public workOrderNumber: string;
  public zoneNumber: string;
  public description: string;
  public skill: string;
  public assigned: string;
  public persona: string = "Lead";
  public customerWorkCard: string;
  public customerWorkOrder: string;
  public contractGroup: string;
  public workCardId: number | string;
  public workCardBarcode: string;
  public assign: boolean;
  public closedCards?: string;
  public customerApproval: boolean;
  public techAuditRequired?: boolean;
  public pcAuditRequired?: boolean;
  public custAuditRequired?: boolean;
  public finalAuditRequired?: boolean;
  public logOnAllowed?: boolean;
}

// Request model for timeinfo clock api call
export class WorkCardTimeClockReqModel {
  public application: string = "CONCOURSE";
  public channel: string = "Browser";
  public divNo: number;
  public itemNumber: string;
  public zoneNumber: string;
  public workCardId: number;
  public token: string;
  public workOrderNumber?:string;
}

//Request model for Hangar Mechanics api call
export class HangarMechanicsReqModel {
  public employeeNumber: string;
  public token: string;
  public divNo: string;
}

//Request model for Employee Profile api call
export class EmployeeProfileReqModel {
  public employeeNumber: number | string;
  public divNo: number | string;
  public token: string;
  public customerRepNumber?:number; // Using for customer profile request only.
}

//Request model for Employee Work Card History api call
export class EmployeeWorkCardHistoryMReqModel {
  public employeeNumber: number;
  public divNo: number;
  public token: string;
  public fromDate: Date;
  public toData: Date;
}

// Request model for workCardFilterCounts api call
export class WorkCardFilterCountReqModel {
  public projectNo: string;
  public divNo: number;
  public token: string;
}

// Request model for Work Card Activity Pin call
export class WorkCardActivityPinReqModel {
  public divNo: number;
  public workCardId: number;
  public activityCode: string;
  public parent: string;
  public parentId: string;
  public token: string;
  public programId: string;
  public terminalId: string;
}

// Request model for Work Card Activity planning status
export class WorkCardActivityPlanningStatusReqModel {
  public divNo: number;
  public workCardId: number;
  public activityCode: string;
  public parent: string;
  public parentId: string;
  public token: string;
  public programId: string;
  public terminalId: string;
}

// Request model for Work Card Activity Add to Card
export class WorkCardActivityAssignReqModel {
  public divNo: number;
  public workCardId: number | string;
  public activityCode: string;
  public parent: string;
  public parentId: string;
  public token: string;
  public programId: string;
  public terminalId: string;
}

//Request model for Employee Daily Schedule
export class EmployeeDailyScheduleReqModel {
  public employeeNumber: number;
  public divNo: number;
  public token: string;
  public fromDate: string = '';
  public toDate: string = '';
}

//Request model for Project Files api call
export class ProjectFilesReqModel {
  public projectId: string;
  public divNo: number | string;
  public token: string;
  public pageNo: number;
  public pageSize: number;
  public headers: boolean;
  public countFilterField: string;
  public sortingField: string;
  public sortingOrder: string;
  public documentType?: string;
}

//Request model for upload file
export class UploadFilesReqModel {
  id: number;
  divNo: number;
  projectNo: string;
  parent: string;
  parentId: number;
  workCardIdentifier: string;
  fileName: string
  operation: string;
  objectType: string;
  token: string;
}

export interface EmpAuthorizations {
  divNo: number;
  employeeNumber: number;
  token: any;
  ojtflag: string;
  auth: string;
  authLockReason?: any;
  successFlag: string;
  errorMessage?: any;
}

export interface EmployeeAuthorizationsRequestModel {
  workcards: WorkCards[];
  workforces: WorkForces[];
}

interface WorkCards {
  divNo: number;
  workCardId: number;
  workOrderNumber: string;
  itemNumber: string;
  zoneNumber: string;
  token: string;
}

interface WorkForces {
  employeeNumber: number;
  divNo: number;
  token: string;
}

export interface PublishAssignmentsReqParams {
  divNo: number;
  programId: string;
  terminalId: string;
  activityCode: string;
  token: string;
  multiCardOverRideFlag?: string;
}

export interface PublishAssignmentsResponse {
  divNo: number;
  workCardId: number;
  activityCode: string;
  parent: string;
  parentId: number;
  token: any;
  programId: string;
  terminalId: string;
  successFlag: string;
  errorMessage?: any;
}

export class FileOperationReqModel {
  id: number;
  divNo: number;
  projectNo: string;
  workCardIdentifier: string;
  fileName: string;
  newFileName: string;
  parent: string;
  parentId: number;
  operation: string;
  programId: string;
  terminalId: string;
  objectType: string;
  token: string;
  newObjectType: string;
}

//Request model to fetch Work Card Files from api call
export class WorkCardFilesReqModel {
  public divNo: number;
  public token: string;
  public workCardId: number;
  public sortingField: string;
  public sortingOrder: string;
  public documentType?: 'workcard' | 'attachment' | 'techData'
}

//Request model for employee current assignment section
export class EmployeeCurrentAssignmentsReqModel {
  public divNo: number;
  public token: string;
  public employeeNumber: number;
}

export interface SplittingModelReqParams {
  divNo: number;
  customerName: string;
  projectNo: string;
  fileName: string;
  token: string;
  splitType?: string;
}

export interface SplittingModel {
  latestModifiedDate?: string;
  taskCardsData: TaskCardsData[];
}

export interface TaskCardsData {
  index: number;
  fields: Fields;
  valid: boolean;
  message?: string;
  fieldsMessages?: string;
  workCardId?: string;
  skip?: boolean;
  warning?: boolean;
}

export interface Fields {
  startPage: number;
  taskCardNumber: string;
  revisionDate?: any;
  revisionNumber?: string;
  sequence: number;

  newTaskCard?: boolean;
  index?: number;
  startPageMessage?:string;
  taskCardNumberMessage?:string;
  sequenceMessage?: string;
  warning?: boolean;
  valid?: boolean;
  skip?: boolean
}

export interface ValidateCardReqParams {
  divNo: number;
  projectNumber: string;
  customerCardNumber: string;
  token: string;
}

export interface ValidateCardModel {
  divNo: number;
  projectNumber: string;
  customerCardNumber: string;
  token: number;
  successFlag: string;
  message: string;
}

export interface FileInput {
  divNo: number;
  customerName: string;
  projectNo: string;
  fileName: string;
  token: string;
}

export interface SplitMetadataUpdateReqParams {
  input: FileInput;
  taskCardsData: TaskCardsData[];
}

export interface TaskCardMetaData {
  index: number;
  fields: Fields;
  workCardId: string;
  valid: boolean;
  message?: any;
}

export interface Result {
  taskCardMetaData: TaskCardMetaData;
  success: boolean;
  message?: any;
}

export interface SplitFinishModel {
  successCount: number;
  result: Result[];
}

//Request model to fetch employee division from api call
export class EmployeeDivisionsReqModel {
  public employeeNumber: string;
  public token: string;
}

//Request model for Required Parts api call
export class RequiredPartsReqModel {
  public token: string;
  public divNo: number;
  public workOrderNumber: string;
  public projectNumber: string;
  public zoneNumber: string;
  public itemNumber: string;
  public pageNo: number;
  public pageSize: number;
}

//Request model for employee PIN validation
export class EmployeePinValidationReqModel {
  public employeeNumber: string;
  public divNo: number;
  public pin: string;
}

// Request model for tools api call
export class ToolsReqModel {
  public token: string;
  public divNo: number;
  public workCardId: number;
  public pageNo: number;
  public pageSize: number;
}

export interface ApplyStampReqParams {
  divNo: number,
  workCardId: number,
  cardStepId: number,
  activityCode: string,
  activityEmployeeNumber: string,
  token: string,
  operation: string,
  comment?: string,
  riiFlag?: string,
  result?: string,
  cardErrorReferenceId?: string,
  stampNumberId?: number,
  customerCardStepId?: string | number,
  correctiveAction?: string,
  fallBackCard?: string,
  customerCardSteps?: CIRStepsRequestModal[]
  programId?: string,
  terminalId?: string,
  ftyAutoLabor?: string;
  stepCount?: number;
  documentType?:string;
}

export interface ApplyStampResponse {
  O_PR_CARD_STEP_ACTIVITIES_ID: number,
  O_SUCCESS_FLAG: string,
  O_ERROR_MESSAGE: string
}

export interface WorkStepActionReqParams {
  divNo: number,
  workCardId: number,
  description: string,
  token: string,
  operation: string,
  inspectorSignOffFlag?: string,
  prCardStepsId?: number,
  sourceStepId?:number,
  documentType?:string
}

export interface WorkStepActionResponse {
  I_PR_CARD_STEPS_ID: number,
  O_SUCCESS_FLAG: string,
  O_ERROR_MESSAGE: string
}

export class validatingLaborClockReqModel {
  public divNo: number;
  public workOrderNumber: string;
  public zoneNumber: string;
  public itemNumber: string;
  public workDate: string;
  public employeeNumber: string;
  public token: string | number;
}

export interface WorkcardSteps {
  divNo: number,
  projectNo: string,
  workCardId: number,
  cardStepId?: string | number,
  token: string,
  customerSteps?: boolean,
  workCardSteps?: boolean,
  unMapped?: boolean,
  eieSteps?:boolean,
  all?: boolean
}

export class InspectionQueueReqModel {
  public divNo: string | number;
  public persona: string = 'inspector';
  public token: string;
  public pageNo: number;
  public pageSize: number;
  public sortingField: string;
  public sortingOrder: string;
  public headers: boolean;
  public projectNo: string;
  public hangar: string;
  public awaitingCir?: boolean;
  public maintenance?:boolean;
  public primaryInspection?:boolean;
}

export class RejectInspectionErrorOptionModel {
  public divNo: string | number;
  public token: string;
  public error?: string;
  public issue?: string;
}

export class RejectInspectionErrorModel {
  public DESCRIPTION: string;
  public DIV_NO: string | number;
  public ERROR_TYPE_CODE: string;
  public ISSUE_CODE: string;
  public ITEM_CODE: string;
  public CARD_ERROR_REFERENCE_ID: string;
  public TOKEN: string;
}
/** user signature stamp model */
export class SignatureBlobResponse {
  DIV_NO: number;
  EMPLOYEE_NUMBER: number;
  STAMP_BLOB: string;
  STAMP_NUMBER: string;
  STAMP_NUMBER_ID: number;
  STAMP_PERSONA?: string;
  STAMP_TYPE: string;
  TOKEN: number;
  WITHDRAWN_STAMP_BLOB?: string;
}

/** user signature stamp model */
export class SignatureBlobRequest {
  divNo: number;
  employeeNumber?: number;
  token: string;
  image?: boolean;
  scale?: number;
  includeWithdrawn?: boolean;
  stampText?: string;
}

//Payload for requesting employee stamps.
export class EmployeeStampsReqModel {
  public divNo: string | number;
  public employeeNumber: string;
  public token: string;
  public discrepancy?: boolean;
}

// Request model for customer projectList api call
export class CustomerProjectListReqModel {
  public resourceNo: string;
  public divNo: string | number;
  public persona: string = 'customer';
  public token: string;
  public pageNo: number;
  public pageSize: number;
  public sortingField: string;
  public sortingOrder: string;
  public projectNo: string;
  public customerName: string;
  public status: string;
  public headers: boolean;
}

/** Task-20211 */
export class CustomerCIRStepReqModel {
  divNo: number;
  workCardId: number;
  description?: string;
  token: string;
  operation: string;
  customerNumber: string;
  customerCardStepId?: string | number;
  parentStepId?: string | number;
  approvalFlag?: string;
  approvalReason?: string;
  pageNumber?: number;
  positionY?: number;
  positionX?: number;
  parent?: string;
  approvalStatus?:string;
  fallBackCard?: string;
}

/** Task-20211 */
export class CustomerCIRStepResModel {
  IO_CUSTOMER_CARD_STEP_ID: number;
  O_SUCCESS_FLAG: string;
  O_ERROR_MESSAGE: string;
}

// Request model for Updating work card.
export class UpdateWorkCardsReqModel {
  public divNo: string | number;
  public workCardId: number;
  public paperWorkFlag: string;
  public token: string;
  public airworthinessDirectiveFlag?: string
}

// Request model for Updating work card.
export class ModifyWorkCardsReqModel {
  public divNo: string | number;
  public workCardId: number;
  public token: string;
  public revisionDate?: string;
  public revisionNumber?: string;
  public paperWorkFlag?: string;
  public displaySequence?: string;
  public stampedByRepDate?: string;
  public stampedByRepNo?: string;
  public stampedNumberIdRep?: string;
  public airworthinessDirectiveFlag?: string;
  public partRequiredFlag?: string;
  public majorRepairFlag?: string;
  public sdrFlag?: string;
  public leakCheckFlag?: string;
  public flightCriticalTaskFlag?: string;
  public alterationFlag?: string;
  public riiFlag?: string;
  public criticalFlag?: string;
  public idtFlag?:string;
}

// Request model for login/logout, start/stop labor clock api
export class LaborClockTrackingReqModel {
  public divNo: string | number;
  public token: string;
  public workOrderNumber: string;
  public zoneNumber: string;
  public itemNumber: string;
  public operation: string;
}

// Response model for login/logout, start/stop labor clock api
export class LaborClockTrackingResModel {
  public token: string | number;
  public divNo: string | number;
  public workOrderNumber: string;
  public zoneNumber: string;
  public itemNumber: string;
  public operation: string;
  public successFlag: string;
  public errorMessage: string;
}

//Request model for parallel editing api call
export class WorkCardPDFAccessReqParams {
  public divNo: string | number;
  public fileId: string | number;
  public programId: string;
  public terminalId: string;
  public signOut: string;
  public token: string;
}

//Response model for parallel editing api call
export class WorkCardPDFAccessResponse {
  public O_SUCCESS_FLAG: string;
  public O_ERROR_MESSAGE: string;
}

// Request model for get annotation data
export class AnnotationBlobDataReqModel {
  public projectNo: string;
  public workCardIdentifier: string;
  public fileName: string;
  public objectType?: string;
}


//Request model for generate continuation sheet api call
export class GenerateContinuationSheetReqParams {
  public divNo: string | number;
  public token: string;
  public workCardId: string;
  public projectNo: string;
  public workOrderNumber: string;
  public zoneNumber: string;
  public itemNumber: string;
  public serialNo: string
  public customerNo?: string;
  public customerCardNo?: string;

}

//Response model for generate continuation sheet api.
export class GenerateContinuationSheetResponse {
  public success: boolean;
  public message: string;
}

// Request model for audit Closure Review data
export class AuditClosureReviewReqModel {
  public divNo: number | string;
  public workCardId: number;
  public token: string;
  public verifierType?: string;
}

// Response model for audit Closure Review data
export class AuditClosureReviewResModel {
  public DIV_NO: number | string;
  public WORK_CARD_ID: number;
  public PR_CARD_AUDIT_ACTIVITIES_ID: number;
  public VERIFIER_TYPE: string;
  public VERIFIER_STAMP_DATE: string;
  public VERIFIER_STAMP_ID: number;
  public VERIFIER_APPROVAL_STATUS: string;
  public VERIFIER_APPROVAL_REASON: string;
  public CURRENT_FLAG: string;
  public ACTIVE: string;
  public VERIFIER_APPROVAL_DATETIME: string;
  public VERIFIER_NUMBER: string;
  public VERIFIER_NAME: string;
  public TOKEN: string;
  public VERIFIER_APPROVAL_DATE_FORMATTED: string;
  public VERIFIER_STAMP_DATE_FORMATTED: string;
  public ERROR_TYPE_CODE?: string;
  public ISSUE_CODE?: string;
  public ITEM_CODE?: string;
  public CORRECTIVE_ACTION?: string;
}

// Request model for audit Process Activity
export class AuditProcessActivityReqModel {
  public divNo: number | string;
  public verifierApprovalStatus: string;
  public token: string;
  public verifierApprovalReason: string;
  public workCardId: string;
  public verifierStampId?: number;
  public verifierType?: string;
  public cardErrorReferenceId: string;
  public correctiveAction?: string;
}

// Request model for audit Process Resubmit Activity
export class AuditProcessResubmitActivityReqModel {
  public divNo: number | string;
  public token: string;
  public workCardId: number;
}

//Response model for audit process activity api call
export class AuditProcessActivityResponse {
  public O_SUCCESS_FLAG: string;
  public O_ERROR_MESSAGE: string;
}

//Response model for audit process resubmit activity api call
export class AuditProcessResubmitActivityResponse {
  public O_SUCCESS_FLAG: string;
  public O_ERROR_MESSAGE: string;
}

//Request model for Location api call
export class LocationReqModel {
  public token: string;
  public divNo: number | string;
}

//Request model for skill api call
export class SkillReqModel {
  public token: string;
  public divNo: number | string;
}

//Request model for ATA api call
export class AtaCodeReqModel {
  public token: string;
  public divNo: number | string;
}

//Request model for discrepancy header data api call
export class DiscrepancyHeaderReqModel {
  public token: string;
  public divNo: number | string;
  public workOrderNumber: string;
  public projectNo: string;
  public inspectorNumber: string;
}

// Request model for records queue
export class RecordsQueueReqModel {
  public divNo: string | number;
  public persona: string = 'records';
  public token: string;
  public pageNo: number;
  public pageSize: number;
  public sortingField: string;
  public sortingOrder: string;
  public recordStatus: string;
  public projectNo: string;
}

//Request model to fetch event history from api call
export class EventHistoryReqModel {
  public divNo: number;
  public token: string;
  public workOrderNumber: number;
  public zoneNumber: string;
  public itemNumber: string;
  public projectNo: string;
}

// Request model for get previous project workcard export  details
export class ProjectWorkCardExpDataReqModel {
  public divNo: string | number;
  public projectNo: string;
  public token: string;
}

// Request Model for getting parts list
export class PartsListReqModel {
  public token: string;
  public partNo: string;
  public pageNo: number;
  public pageSize: number;
}

// Response Model for getting parts list
export class PartsListRespModel {
  count: number;
  paginationData: PartsListModel[];
}

// Model for parts list
export class PartsListModel {
  FSC: string;
  PART: string;
  PART_DESCRIPTION: string;
  PSPEC_ID: number;
  TOKEN: number;
  ROWNUMBER: number;
}
// Response modal for save annotation
export class SaveAnnotationResModel {
  success: boolean;
  message: string;
}

// New parts requisition order api request model
export class NewPartRequisitionOrderReqModel {
  public divNo: number;
  public workCardId: number;
  public orderLine?: number = null;
  public partNumber: string;
  public partDescription: string;
  public fsc?: string = null;
  public partId?: number = null;
  public dueDate: string;
  public quantity: number;
  public cspFlag: string = null;
  public repairFlag?: string = null;
  public alternateAllowedFlag: string = null;
  public internalComment: string = null;
  public token: string;
}

// New parts requisition order api response model
export class NewPartRequisitionOrderResModel {
  public divNo: number;
  public workCardId: number;
  public orderLine: string = null;
  public partNumber: string;
  public partDescription: string;
  public fsc: string = null;
  public partId: string = null;
  public dueDate: string;
  public quantity: number;
  public cspFlag: string = null;
  public repairFlag: string = null;
  public alternateAllowedFlag: string = null;
  public internalComment: string = null;
  public token: string;
  public employeeNumber: number;
  public requisitionNumber: number;
  public successFlag: string;
  public errorMessage: string;
}

//Request model for Required Parts api call
export class IssueTagsReqModel {
  token: string;
  workOrderNumber: string;
  projectNumber: string;
  zoneNumber: string;
  itemNumber: string;
}

export class IssueTagsResModel {
  REQ_ITM_ISS_ID: number;
  TAG: string;
  BLTITM_ID: number;
  BAR_CODE: string;
  PART_ID: number;
  PART: string;
  KEYWORD: string;
  QUANTITY: number;
  ROTABLE: string;
  REQISS_STATUS: string;
  CND: string;
  DIV_NO: number;
  CUSTOMER_NO: number;
  CUSTOMER_NAME: string;
  TRACE_NAME: string;
  MRO_CUSTOMER_NUMBER: number;
  MRO_CUSTOMER_NAME: string;
  REQITM_ID: number;
  UOM: string;
  GROUP_CODE: string;
  GROUP_DESC: string;
  DIST_ORDER: string;
  DIST_ORDER_LINE: string;
  VENDOR: string;
  ACQ: string;
  DIST_REVISED: string;
  REQUISITION_ID: number;
  PROJECT_NUMBER: string;
  TAIL_NUMBER: string;
  WORK_ORDER_NUMBER: string;
  ZONE_NUMBER: string;
  ITEM_NUMBER: string;
  REQUISITION_NUMBER: number;
  REQUESTED_BY: string;
  DUE_DATE: string;
  REQ_STATUS: string;
  REQ_CREATION_DATE: string;
  REQISS_LINE: number;
  ORDER_PART_NUMBER: string;
  ORDER_PART_DESCRIPTION: string;
  REPAIR_FLAG: string;
  CSP_FLAG: string;
  REQITM_STATUS: string;
  FSC: string;
  SERIAL_NUMBER: string;
  LOT_NUMBER: string;
  AAR_FORM: string;
  REV: string;
  TOKEN: string;
  PO_NUMBER: number;
}

export class RoutineStepsGroupActivityReqModal {
  public divNo: number;
  public workCardId: number;
  public operation: string;
  public verifierType: string;
  public verifierApprovalStatus: string;
  public verifierApprovalReason?: string;
  public token: string;
  public riiFlag?: string;
  public activityCode?: string;
  public rtnCardStampsGroupId?: number;
  public cardErrorReferenceId?: number;
  public customerStepId?: number;
  public parentStepId?: number;
  public correctiveAction?: string;
  public fallBackCard?: string;
  public customerCardSteps?: CIRStepsRequestModal[];
  public programId?: string;
  public terminalId?: string;
  public ftyAutoLabor?: string;
  public documentType?: string;
}

export class CIRStepsRequestModal {
  public divNo?: number;
  public workCardId?: number;
  public customerCardStepId: number;
}

export class RoutineStepsGroupActivityResModal {
  public IO_RTN_CARD_STAMPS_GROUP_ID: string;
  public O_SUCCESS_FLAG: string;
  public O_ERROR_MESSAGE: string;
}

export class RoutineWorkCardStepReqModel {
  divNo: number;
  workCardId: number;
  operation: string;
  documentType: string;
  description?: string;
  pageNumber: number;
  positionX: number;
  positionY: number;
  token: string;
  stampNumberIdBy?: string | number;
  rtnCardStampsActivityId: string;
  verifierType?: string;
  rtnCardStampsGroupId?: number;
  groupId?: number;
  stampNumber?: string;
  annotationType?: string;
}
export class WorkCardStepResModel {
  IO_RTN_CARD_STAMPS_ACTIVITY_ID: number;
  O_SUCCESS_FLAG: string;
  O_ERROR_MESSAGE: string;
}

// Request model for manage discrepancy api call
export class ManageDiscrepancyListReqModel {
  public divNo: string | number;
  public workCardId: number;
  public token: string;
}

export class WithdrawDetailsReqModal {
  divNo: string | number;
  statusType: string;
  parentId: number;
  status: string;
  reason: string;
  token: string;  
  correctiveAction?: string;
  statusReasonList?: boolean;
}

export class WithdrawDetailsResModal {
  O_EMPLOYEE_NUMBER: number;
  O_FIRST_NAME: string;
  O_MIDDLE_INITIAL: string;
  O_LAST_NAME: string;
  O_PERFORMED_DATE: string;
  O_PERFORMED_DATE_FORMATTED: string;
  O_CORRECTIVE_ACTION: string;
}
// request model to fetch inspection button status
export class RoutineInspectionFlagReqModel {
  public divNo: string | number;
  public workCardId: number;
  public projectNo: string;
  public token: string;
  public groupId?: number;
}

// response model to fetch inspection button status
export class RoutineInspectionFlagResModel {
  public STATUS: string;
  public STATUS_REASON: string;
  public GROUP_ID: number;
  public PROJECT_NUMBER: string;
  public DIV_NO: number;
  public TOKEN: number;
  public WORK_CARD_ID: number;
  public CORRECTIVE_ACTION?: string;
  public REQUEST_TYPE?: string;
  public INSPECTION_TYPE?: string;
  public RII_FLAG?: string;
  public CURRENT_ACTIVITY_COMMENT?: string;
  public REQUESTED_BY_EMPLOYEE_NUMBER?: string;
  public INSPECTED_BY_FULL?: string;
  public REQUESTED_BY_FULL_NAME?:string;
  public INSPECTED_BY_EMPLOYEE_NUMBER?: string;
}

// Request model to fetch stamps details
export class RoutineStepGroupIdListReqModel {
  public divNo: string | number;
  public groupId?: string;
  public verifierType?: string;
  public workCardId: number;
  public projectNo: string;
  public token: string;
}

// Response model to fetch stamps details
export class RoutineStepGroupIdListResModel {
  public ROUTINE_CARD_STAMP_ID: number;
  public DIV_NO: number;
  public WORK_CARD_ID: number;
  public STAMPED_BY_PERSON_NUMBER: number;
  public STAMPED_BY_PERSON_DATE: string;
  public STAMP_NUMBER_ID_BY: string;
  public ROUTINE_CARD_STAMP_DESC: string;
  public PAGE_NUMBER: number;
  public Y_POSITION: number;
  public ACTIVE: string;
  public DT_CREATED: string;
  public DT_MODIFIED: string;
  public GROUP_ID: number;
  public VERIFIER_TYPE: string;
  public STATUS: string;
  public STATUS_REASON: string;
  public TOKEN: number;
}

export class CardClosureSceptreDetailsReqModel {
  public divNo: string | number;
  public parent: string = "PR_WORK_CARDS";
  public parentId: number;
  public formId: string = "UAL_NRC";
  public columnId: string = "SCEPTRE_ENTRY_REQUIRED";
  public token: string;
  public customerNo: number;
  public employeeNameReq: string;
}

export class AddSceptreFlagReqModel {
  public divNo: string | number;
  public parent: string = "PR_WORK_CARDS";
  public parentId: number;
  public formId: string = "UAL_NRC";
  public columnId: string = "SCEPTRE_ENTRY_REQUIRED";
  public data: string;
  public programId: string;
  public terminalId: string;
  public token: string;
}

//Request model for reason api call
export class ReasonReqModel {
  public token: string;
  public divNo: number | string;
  public projectNo: string;
}

// Request model for labor clock summary api
export class LaborTimeAndCardCountReqModel {
  public divNo: number | string;
  public employeeNumber: number;
  public workDate: string;
  public token: string;
}

// Response model for labor clock summary api
export class LaborTimeAndCardCountResModel {
  O_ELAPSED_TIME: string;
  O_NO_OF_CARDS: number;
}

// Request model for get discrepancy api call
export class EvaluteNRCReqModel {
  public divNo: string | number;
  public newWorkCardId: number;
  public token: string;
}

//Request model for contract group api call
export class ContractGroupReqModel {
  public token: string;
  public divNo: number | string;
  public customerNo: string;
  public workOrderNumber: string;
}

//Request model for dot number api call
export class DotNumberReqModel {
  public token: string;
  public divNo: number | string;
  public projectNo: string;
  public stampNumber: string;
}

// Request model for routine customer steps
export class RoutineCustomerStepsReqModel {
  public token: number | string;
  public workCardId: number;
  public divNo: number;
  public sortingField: string;
  public sortingOrder: string;
  public customerStepId?: number;
}

// Request model for routine customer steps
export class RoutineCustomerStepsResModel {
  public CS_DIV_NO?: number;
  public CS_WORK_CARD_ID?: number;
  public CS_CUSTOMER_ID?: number
  public CS_CUSTOMER_NAME?: string;
  public CS_DESCRIPTION?: string;
  public CS_PERFORMED_BY_CUST_REP_NUMBER?: number;
  public CS_CUSTOMER_CARD_STEPS_ID?: number
  public CS_PARENT_STEP_ID?: number;
  public CS_DT_CREATED?: string;
  public CS_APPROVAL_STATUS?: string;
  public CS_APPROVAL_REASON?: string;
  public CS_DT_MODIFIED?: string;
  public CS_DT_MODIFIED_FORMATTED?: string;
  public CS_X_POSITION?: number;
  public CS_Y_POSITION?: number;
  public CS_PAGE_NUMBER?: number;
  public CS_PARENT?: string;
  public CS_TOKEN?: number;
  public CS_DT_CREATED_FORMATTED?: string;
  public DIV_NO?: number;
  public WORK_CARD_ID?: number;
  public CUSTOMER_ID?: number
  public CUSTOMER_NAME?: string;
  public DESCRIPTION?: string;
  public PERFORMED_BY_CUST_REP_NUMBER?: number;
  public CUSTOMER_CARD_STEPS_ID?: number
  public PARENT_STEP_ID?: number;
  public DT_CREATED?: string;
  public APPROVAL_STATUS?: string;
  public APPROVAL_REASON?: string;
  public DT_MODIFIED?: string;
  public DT_MODIFIED_FORMATTED?: string;
  public X_POSITION?: number;
  public Y_POSITION?: number;
  public PAGE_NUMBER?: number;
  public PARENT?: string;
  public TOKEN?: number;
  public DT_CREATED_FORMATTED?: string;
  public isSelected?: boolean = false;
  public CUST_REP_FIRST_NAME?: string;
  public CUST_REP_LAST_NAME?: string;
  public ACTIVE?: 'Y' | 'N';
}

// Request model for workcardlist api call
export class ActiveWorkCardListReqModel {
  public employeeNumber: string;
  public divNo: string | number;
  public token: string;
}
//Request model to check employee is authorize for multi card or not.
export class EmployeeMultiCardAuthCheckReqModel {
  public employeeNumber: string;
  public divNo: number | string;
  public workOrderNumber: number;
  public zoneNumber: string;
  public itemNumber: string;
  public token: string;
  public leadFlag?: string;
}

//Response model to check employee is authorize for multi card or not.
export class EmployeeMultiCardAuthCheckRespModel {
  public result: string;
}
/** user signature stamp model for customer */
export class CustomerSignatureBlobRequestModel {
  customerNumber: number;
  customerShortName: string;
  stampNumber: string;
  scale?: number;
}

export class ConfirmAuthRequestModel {
  public divNo: string | number;
  public workCardId: number;
  public employeeNumber: string;
  public token: number | string;
}

export class ConfirmAuthResponseModel {
  public O_SUCCESS_FLAG: string;
  public O_ERROR_MESSAGE: string;
}

export class LeadAssignmentAuthCheckRequestModel {
  public divNo: string | number;
  public leadEmployeeNumber: number;
  public token: number | string;
  public date: string;
}

/* START: Request payload for export project API */
export class ExportProjectRequestModel {
  public divNo: string | number;
  public token: number | string;
  public projectNo: string;
  public projectId: string;
  public closureReview: boolean;
  public tools: boolean;
  public parts: boolean;
  public techData: boolean;
  public attachments: boolean;
  public includeWorkOrders: IncludeWorkOrders[] = [];
  public exportName: string;
}

export class IncludeWorkOrders {
  public workOrderType: string;
  public workOrderNumber: string;
}
/* END: Request payload for export project API */

export class ExportSingleProjectReqModel {
  public token: number | string;
  public divNo: string | number;
  public projectId: string;
  public projectNo: string;
  public closureReview: boolean;
  public parts: boolean;
  public tools: boolean;
  public techData: boolean;
  public attachments: boolean;
}

//Request model to get the publish and unpublish work card
export class WorkCardListReqModel {
  public employeeNumber: string | number;
  public divNo: string | number;
  public projectNo: string;
  public token: string
  public pageNo: number;
  public pageSize: number;
  public headers: boolean;
  public persona: string = "technician";
  public workCardId: number | string;
  public unpublished: boolean;
}

export class UpdatePrimarySkillsRequestModel {
  public divNo: string | number;
  public workCardId: number;
  public skill: string;
  public token: number | string;
}

export class UpdatePrimarySkillsResponseModel {
  public O_SUCCESS_FLAG: string;
  public O_ERROR_MESSAGE: string;
}

// Request model for fetch workcardId specific skills.
export class WorkCardSkillsRequestModel {
  public divNo: string | number;
  public workCardId: number;
  public token: string;
}

// Response model for Updated skill.
export class WorkCardSkillsResponseModel {
  public SKILL_CODE: string;
  public PRIMARY_SKILL_FLAG: string;
}

// Request model for fetch status reason.
export class StatusReasonListRequestModel {
  public divNo: string | number;
  public workCardId: number;
  public status: string;
  public statusReason: string;
  public token: string;
}

// Request model to fetch robbed and rotable parts data
export class RotableAndRobbedPartsListReqModel {
  public divNo: string | number;
  public workCardId: number;
  public token: string;
}

// Request model to post robbed and rotable parts data
export class AddRotableAndRobbedPartsReqModel {
  public divNo: string | number;
  public workCardId: number;
  public partsNumberOff: string;
  public serialNumberOff: string;
  public rotablePartChangedFlag: string;
  public partNumberOn: string;
  public serialNumberOn: string;
  public robbedPartsFlag: string;
  public exceptionDetails: string;
  public mePartNumber: string;
  public token: string;
  public operation: string;
  public cardRoatablePartId?: number;
  public active?: string;
}

//Tech Library response modal
export class TechLibraryResModal{
  public divNo: number;
  public fileName: string;
  public id: number;
  public message: string;
  public newFileName: string;
  public objectType: string;
  public operation: string;
  public parent: string;
  public parentId: number;
  public projectNo: string;
  public revisionDate: string;
  public revisionNo: string;
  public sequenceNo: string;
  public successFlag: string;
  public token: string;
  public workCardIdentifier: string;
}

// Request payload modal for copy to work cards
export class AddFromSkippedWorkCardsReqModal {
  public id: number;
  public divNo: string | number;
  public parent: string;
  public parentId: number | string;
  public projectNo: string;
  public workCardIdentifier: string;
  public fileName: string;
  public newFileName: string;
  public objectType: string;
  public operation: string;
  public revisionDate?: string;
  public revisionNo?: number;
  public sequenceNo?: number | string;
  public token: string | number;
  public successFlag?: string;
  public message?: string;
}

// Request model for projectList api call
export class LaborDirectFlagReqModel {
  public employeeId: string;
  public token: string;
}

// Request model for StandByCards api call
export class PrevWorkCardsAndStndByCardReqModel{
  public token: string;
  public divNo: string | number;
  public workDate: string;
  public workCardId: number | string;
}

// Request model for fetch wc note
export class EmployeeWorkCardNoteReqModel {
  public token: string;
  public divNo: string | number;
  public parentId: number; //workCardId
}

// Request model for save wc note
export class SaveEmployeeWorkCardNoteReqModel {
  public token: string;
  public divNo: string | number;
  public parentId: number; //workCardId
  public commentText: string;
  public employeeNumber: string | number;
  public operation: string;
}

// Response model for save wc note
export class SaveEmployeeWorkCardNoteRespModel {
  public O_SUCCESS_FLAG: string;
  public O_ERROR_MESSAGE: string;
}

export class CreateTurnOverReportReqModel {
  public divNo: string | number;
  public projectNumber: string;
  public status: string;
  public entryTypeStatus: string;
  public description: string = '';
  public skillCode: string = '';
  public shiftCode: string = '';
  public operation: string;
  public token: string;
  public turnOverReportId: number;
}

//Request model for ShiftCodes api call
export class ShiftCodesReqModel {
  public token: string;
  public divNo: number | string;
}

//Request model for getTurnOverType api call
export class TurnOverTypeReqModel {
  public token: string;
  public divNo: number | string;
}

// Request model for turnover report data
export class ReadTurnOverReportReqModel {
  public divNo: string | number;
  public projectNo: string;
  public token: string;
}

// Request model for MRO Project Manger projectList api call
export class MROProjectManagerProjectListReqModel {
  public divNo: string | number;
  public persona: string;
  public token: string;
  public pageNo: number;
  public pageSize: number;
  public projectNo: string;
  public checkType: string;
}

// Request model for MRO Project Manger projectList api call
export class UpdateTargetStartDayReqModel {
  public divNo: string | number;
  public sourceProjectNumber: string;
  public targetProjectNumber: string;
  public token: string;
  public updateOption: string;
  public workCardId?:number;
  public targetStartDay?:number;
}

//Update Target Start Day Response Model
export class UpdateTargetStartDayResModel {
  O_SUCCESS_FLAG: string;
  O_ERROR_MESSAGE: string;
  O_CARDS_UPDATED: number;
  O_TOTAL_CARDS: number;
}

//This model used to update particular row on parent grid.
export class TargetStartDayValueAffectedModel{
  isTouched:boolean = false;
  WORK_CARD_ID:number;
  newTargetDayVal:string;
}

// Request model for get void cir api call
export class ConfirmVoidCirReqModel {
  public divNo: string | number;
  public workCardId: number;
  public token: string;
  public description: string;
  public operation: string;
  public customerNumber: string;
  public customerCardStepId: number;
}

//Request model to fetch Work Card Files from api call
export class LogPageNumberReqModel {
  public divNo: string | number;
  public token: string;
  public workCardId: number;
  public logPageDescription:string;
  public operation:string;
  public logPageId:number;
}

//Request model for clokedin Techs api call
export class ClokedInTechsReqModel {
  public token: string;
  public divNo: number | string;
  public projectNo: string;
}

//Update request model
export class UpdateRIModel {
  public comment: string;
  public riiFlag: string | boolean;
}

// Update closure review modal
export class UpdateClosureReviewModel {
  public correctiveAction: string;
  public reviewComments: string;
}

// Request model to fetch Published Count from api call
export class WorkCardAssignmentCountReqModel {
  public token: string;
  public divNo: number | string;
  public projectNo: string;
  public workDate: string;
  public publishFlag?: string;
  public workCardId?: number;
}
// Response model to hold Published Count from api call
export class WorkCardAssignmentCountResponseModel {
  public COUNT: number;
}
// Response model for user roles
export class UserRolesRespModel {
  public id: number;
  public name: string;
  public normalizedName: string;
  public concurrencyStamp: string;
}

// Request model to fetch active inspection
export class ActiveInspectionReqModel {
  public divNo: string | number;
  public employeeNumber: string | number;
  public token: string;

}

// Response model to fetch active inspection
export class ActiveInspectionResModel {
  public CARD_STEP_ID?: number;
  public DIV_NO: number;
  public GROUP_ID?: number;
  public INSPECTED_BY_EMPLOYEE_NUMBER?: string;
  public PRIMARY_INSPECTION_FLAG?: string;
  public PROJECT_NUMBER: string;
  public TOKEN: number;
  public WORK_CARD_ID: number;
  public WORK_ORDER_NUMBER: number;
  public ZONE_NUMBER: number;
  public ITEM_NUMBER: number;
  public DOCUMENT_TYPE:string;
}


export class WorkStepFinalActionReqModel {
  public divNo: number | string;
  public workCardId: number;
  public stepNumber?: number;
  public stepName: string;
  public description?: string;
  public documentType: string;
  public pageNumber: number;
  public itemNumber: string;
  public correctiveAction: string;
  public status: string;
  public statusReason: string;
  public createdByEmployeeId: number;
  public sourceCardId: number;
  public sourceCardStepId: number;
  public programId: string;
  public terminalId: string;
  public inspectorSignOffFlag: string;
  public sourceStepId: number;
  public token: number;
  public operation: string;
  public activityCode: string;
  public activityEmployeeNumber: number;
  public result: string;
  public reason: string;
  public comment: string;
  public ftyAutoLabor: string;
  public riiFlag: string;
  public stepCount: number;
  public startDate: Date;
  public cardErrorReferenceId: number;
  public stampNumberId: number;
  public stampNumber: string;
  public groupId?: number;
  public annotationType?: string;
  public verifierType: string;
  public verifierApprovalStatus: string;
  public verifierApprovalReason: string;
  public fallbackCard: string;
  public cardStepsId: number;
  public routineCardStampsGroupId: number;
  public yposition?: number;
  public xposition?: number;
}

export class AirworthinessDirectiveReqModel {
  public divNo?: string;
  public workCardId?: string;
  public revisionDate?: string | null;
  public revisionNumber?: string | null;
  public displaySequence?: string;
  public paperWorkFlag?: string;
  public stampedByRepDate?: string | null;
  public stampedByRepNo?: string | null;
  public stampedNumberIdRep?: string;
  public rejectReason?: string | null;
  public airworthinessDirectiveFlag?: string;
  public wipStatus?: string | null;
  public wipReason?: string | null;
  public token?: string;
  public note?: string;
}

export class SendToAarPayloadModel {
  public divNo?: number;
  public workCardId?: number;
  public token?: string;
  public comment?: string;
  public receivingPersona?: string;
  public programId?: any;
  public terminalId?: any;
}

// update user PIN number
export class userPinReqModel {
  public divNo: number | string;
  public token: string;
  public newPin: number;
}

//Response model for pin update api call
export class userPinUpdateResponse {
  public O_SUCCESS_FLAG: string;
  public O_ERROR_MESSAGE: string;
}

// send tunrover report email
export class TOREmailReqModel {
  public divNo: number | string;
  public messageName: string;
  public logEntryId: string;
  public token: string;
}

//Response model for turnover report email api call
export class TOREmailResponse {
  public O_SUCCESS_FLAG: string;
  public O_ERROR_MESSAGE: string;
}
